<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <hr>
    <b-row>
      <b-col md="9">
        <h2>{{ textTemplate(template) }}</h2>
        <div class="white-bg-container">
          <b-row>
            <b-col>
              <b-form-group
                label="SUBJECT:"
                label-for="subject"
                label-cols-sm="2"
              >
                <b-form-input
                  id="subject"
                  v-model="Subject"
                  placeholder=""
                  name="subject"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="textTemplate(template) + ' Text:'"
                label-for="emailBodySection2"
              >
                <div
                  id="emailBodySection2"
                  class="expanded-textarea"
                >
                  <textarea
                    v-model="Body"
                    class="expandable-textarea"
                    contentEditable
                    :readonly="readOnly"
                    rows="15"
                    style="font-family: 'Consolas', 'Courier New'"
                  />
                  
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col class="pt-3">
        <div class="white-bg-container">
          <b-row class="pb-1">
            <b-col>
              Template Tokens
            </b-col>
          </b-row>
          <b-row
            v-for="tempToken in TemplateTokens"
            :key="tempToken"
          >
            <b-col>
              {{ tempToken }}
            </b-col>
          </b-row>

        </div>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="saveTemplate()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      Body: "",
      Subject: "",
      readOnly: false,
      template: "",
      templateID: "",
      TemplateTokens: []
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Templates',
          href: '/admin/templates'
        },
        {
          text: this.textTemplate(this.template),
        },
      ]
    },
  },
  watch: {},
  async created() {
    this.template = this.$route.meta.templateId;
    this.templateID = this.$route.meta.templateId;
    try {
      this.getTemplate(this.templateID);
      this.getTemplateTokens(this.templateID);
      this.getUserInfo();
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    getUserInfo() {
      apiService
          .get("auth/userinfo")
          .then(res => {
            this.userObj = res.data;
          })
    },
    getTemplate(templateID) {
      apiService
          .get("template/" + templateID)
          .then(res => {
            this.Body = res.data.templateData.Body;
            this.Subject = res.data.templateData.Subject;
          })
    },
    getTemplateTokens(templateID) {
      apiService
          .get("template/" + templateID + "/tokens")
          .then(res => {
            this.TemplateTokens = res.data;
          })
    },
    textTemplate(val) {
      switch (val) {
        case "activity-update" : return "Activity Update Template";
        case "operations-manager-notification-template" : return "Operations Manager Notification";
        default: return "Template"
      }
    },
    isEmpty(val) {
      return val ? val : "";
    },
    resetForm() {
      this.$router.push('/admin/templates');
    },
    saveTemplate() {
      const postData = {
        TemplateData: {
          "Subject": this.Subject,
          "Body": this.Body,
        }

      }
      apiService.post("template/" + this.templateID, postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Template updated!');
          this.$router.push('/admin/templates');
        }
      })
    },
  }
}
</script>

<style scoped>

</style>